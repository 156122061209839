/* Do not attempt to change values in themeMap - only change in ./variables.css */
@import "./variables.css";

/* Global Styles */
@font-face {
  font-family: "DIN Condensed Bold";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* prettier-ignore */
  src:
    url("/static/fonts/DINCondensed-Bold.ttf") format("truetype"),
    url("/static/fonts/DINCondensed-Bold.woff2") format("woff2"),
    url("/static/fonts/DINCondensed-Bold.woff") format("woff");
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--secondaryFontFamily), serif;
  font-size: 18px;
  font-weight: 400;
  color: #47566b;
}

body {
  hyphens: none;
  margin: 0;
  min-width: 300px;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
nav,
footer {
  font-family: var(--primaryFontFamily), sans-serif;
  text-transform: uppercase;
}

button,
footer {
  letter-spacing: 2px;
}

footer {
  background-color: var(--secondary);
  color: var(--white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 3px;
  margin: 0;
  padding: 0;
}

a {
  color: currentColor;
}

nav a,
footer a {
  text-decoration: none;
}

p {
  line-height: 1.75;
  max-width: 700px;
}

#root {
  min-height: 100%;
}

/* Custom font scaling formula https://blog.envylabs.com/responsive-typographic-scales-in-css-b9f60431d1c4 */
h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.3rem;
}

h3 {
  font-size: 2.1rem;
}

h4 {
  font-size: 1.9rem;
}

h5 {
  font-size: 1.375rem;
}

h6 {
  font-size: 1.125rem;
}

@media screen and (--large-viewport) {
  html {
    font-size: 16px !important;
  }
}

@media screen and (--small-viewport) {
  html {
    font-size: 14px !important;
  }
}

/* React Scroll Button styles */
.ScrollUpButton {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
}

code {
  background-color: #f0f2f2;
  border-radius: 4px;
  border-style: solid;
  border-color: #f0f2f2;
}
