.NavDesktop {
  height: var(--navBarHeight);
  position: absolute;
  top: var(--navBarTopGutterHeight);
  width: 100%;
  z-index: 10;
}

@media screen and (--mobile-viewport) {
  .NavDesktop {
    display: none;
  }
}

.desktopNavContainer {
  margin: 0 auto;
  max-width: 1200px;
}

.NavDesktop nav {
  background-color: var(--white);
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: space-between;
}

.NavDesktop nav a {
  font-weight: bolder;
}

.NavDesktop nav li {
  list-style: none;
}

.link {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.logoLink {
  height: var(--navBarHeight);
}

.logo {
  align-self: center;
  height: 65%;
  margin-left: 1rem;
}

/* One-off differences from other link items */
.donateLink {
  align-items: center;
  background-color: var(--primary);
  border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
  color: var(--secondary);
  height: 100%;
  padding: 0 2rem;
  transition: color 0.2s linear;
}

.donateLink > span {
  align-self: center;
}

.donateLink:focus,
.donateLink:hover {
  color: var(--white);
  cursor: pointer;
  transition: color 0.2s linear;
}
