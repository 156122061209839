.Card {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.12);
  color: var(--secondary);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  margin: 1rem;
  min-height: 100px;
  min-width: 100px;
  padding: 1.5rem;
}

.Card svg {
  fill: var(--secondary);
}

.Card:focus {
  outline: none;
}

.animatedCard {
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.12);
  transition: 0.2s box-shadow linear;
}

.animatedCard:focus,
.animatedCard:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}
