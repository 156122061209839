.Footer {
  align-items: center;
  display: flex;
  height: 320px;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;
}

.Footer div {
  box-sizing: border-box;
}

.Footer a {
  color: var(--white);
}

.Footer:not(.logoGrouping) a:hover {
  /* Apply on-hover underline to text-based anchors */
  text-decoration: underline;
}

.footerGrouping {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.logoGrouping img {
  height: 40px;
}

.linksGrouping {
  flex-direction: row;
  align-items: flex-start;
  padding-left: 3rem;
}

.linksGrouping ul {
  list-style: none;
  padding-inline-start: 0;
}

.linksGrouping li:not(:last-child) {
  margin-bottom: 12px;
}

.legalGrouping {
  font-size: 0.75rem;
  justify-content: center;
  width: 100%;
}

.copyright {
  justify-content: center;
  margin-bottom: 1rem;
}

.legalLinks {
  max-width: 300px;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.marginBottom {
  margin-bottom: 8px;
}

.linksRow {
  display: flex;
  flex-direction: row;
}

.capitalize {
  text-transform: uppercase;
}

.footerWrapper {
  max-width: 1300px;
}

@media screen and (min-width: 1120px) {
  .linksColumn {
    min-width: 140px;
  }

  .linksGrouping,
  .linksRow,
  .linksColumn {
    flex-grow: 1;
  }

  .footerWrapper {
    padding: 0 50px;
  }

  .socialGrouping {
    max-width: 220px;
  }
}

@media screen and (--extra-large-viewport) {
  .Footer {
    flex-direction: column;
    height: auto;
  }

  .footerGrouping {
    height: auto;
    padding: 1.5rem;
  }

  .socialGrouping {
    width: 100%;
  }

  .linksGrouping {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .linksRow {
    flex: 1 1 50%;
    justify-content: space-around;
  }

  .legalGrouping {
    padding: 1.5rem;
  }
}

@media screen and (--small-viewport) {
  .Footer {
    padding-bottom: 10rem;
  }

  .linksRow {
    flex: 1 1 100%;
  }

  .linksColumn {
    flex: 1 1 50%;
  }

  .linksGrouping {
    margin-left: 2.5rem;
  }

  .linksGrouping li:not(:last-child) {
    margin-bottom: 20px;
  }

  .footerGrouping {
    font-size: 1.25rem;
  }

  .legalGrouping {
    font-size: 1.2rem;
  }
}

@media screen and (--extra-small-viewport) {
  .linksGrouping {
    margin-left: 1.5rem;
  }
}

/*
 * A bit of technical debt here...
 * OutboundLinks with the icon end up larger in height than other links
 */
.lineHeightFix {
  line-height: 22px;
}

@media screen and (--extra-large-viewport) {
  .lineHeightFix {
    line-height: 24px;
  }
}
