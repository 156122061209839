.CloseButton {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  margin: 0.5rem;
  z-index: 1;
}

.CloseButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.CloseButton:hover:not(:disabled) {
  cursor: pointer;
}

.icon {
  padding: 0.25rem;
  width: 20px;
  transform: rotate(45deg);
  transition: 0.2s linear transform;
}

.primary {
  fill: var(--primary);
}

.secondary {
  fill: var(--secondary);
}

.white {
  fill: var(--white);
}

.CloseButton:hover:not(:disabled) .icon {
  transform: rotate(135deg);
  transition: 0.2s linear transform;
}
